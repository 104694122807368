<template>
  <div class="login mainWrap">
    <p class="loginTitle bold mb-0 mt-3">助成業務支援システム</p>
    <p v-if="viewRegister"
      class="mb-1 mt-4">アカウント作成時に登録されたメールアドレス・パスワードを入力してください。</p>
    <p
      :class="[
        viewRegister ? '' : 'mt-4',
        'mb-1'
      ]">ログインは認証成功後、12時間有効です。</p>
      <p class="mb-2">一定時間内にパスワードの認証に5回失敗した場合、24時間ログインできなくなります。</p>
    <b-form id="loginWrap" class="w-50">
      <label class="bold" for="input-default">メールアドレス</label>
      <b-form-input v-model="id"
        class="mb-3 h-50px"
        size="md"
        :autocomplete="'email'"/>

      <label class="bold" for="input-default">パスワード</label>
      <b-form-input type="password"
      class="mb-4 h-50px"
      v-model="password"
      maxlength="255"
      :autocomplete="'current-password'"/>

      <div class="bottonArea flex flexCenter">
        <b-button
          class="w-100 boldBtn"
          variant="primary"
          @click="kickLogin">ログイン</b-button>
      </div>
      <b-alert
        class="mt-3"
        :show="errorMsg!==''"
        variant="warning">{{errorMsg}}</b-alert>
      <div v-if="viewResetPass">
        <p class="mt-4">
          <img class="tri" src="@/assets/svg/tri.svg">
          <b-link
            class="ml-1"
            :to="resetPassUrl">パスワードを忘れた場合</b-link>
        </p>
      </div>
      <div v-if="viewRegister">
        <hr class="my-4"/>
        <div id="newAccount"
          class="bottonArea flex flexCenter">
          <b-button
            class="w-100 boldBtn"
            @click="goInitMail">新しくアカウントを作成</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'LoginComponent',
  props: {
    isCms: {
      type: Boolean,
      default: false,
    },
    login: {
      type: Function,
    },
    viewRegister: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: '',
    },
    viewResetPass: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: '',
      password: '',
    };
  },
  computed: {
    errorMsg() {
      return this.msg;
    },
    resetPassUrl() {
      if (this.isCms) {
        return '/cms/identify/password/recover';
      }
      return '/identify/password/recover';
    },
  },
  methods: {
    kickLogin() {
      const inputs = {
        login_id: this.id,
        password: this.password,
      };
      this.login(inputs);
    },
    goInitMail() {
      this.$router.push({ name: 'gasRegisterInit' });
    },
  },
};
</script>

<style scoped>

  .h-50px {
    height: 50px;
  }

  .login{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #loginWrap{
    padding: 15px 30px;
  }

  .formGroup{
    flex-wrap: wrap;
    display: flex;
    justify-content:center;
    margin: 15px 0;
  }

  .tri {
    margin-bottom: 2px;
  }

  .loginTitle {
    font-size: 24px;
  }

  .boldBtn {
    font-weight: bold;
  }

</style>
