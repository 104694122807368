<template>
  <div class="auth-c">
    <div class="messageWrap">
      <div class="message">
        ご登録のメールアドレスに確認コードを送信しました。<br>
        受信したメールに記載の確認コードを入力し、ログインしてください。
      </div>
      <div class="subMessage">
        ※ 30分以上経過してもメールが届かない場合は、大変お手数ですが再度最初からお試しください。
      </div>
    </div>
    <div class="inputWrap">
      <v-form-text
        label="確認コード"
        labelSize="small"
        itemStyle="block"
        v-model="code"
        maxLength="255"
      ></v-form-text>
    </div>
    <b-button
      class="login"
      @click="codeSend"
      :disabled="!code"
    >
      ログイン
    </b-button>
    <b-alert
      class="mt-3 warning"
      :show="errorMsg!==''"
      variant="warning">{{errorMsg}}</b-alert>
  </div>
</template>

<script>
export default {
  name: 'AuthCComponent',
  props: {
    login: {
      type: Function,
    },
    msg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: '',
    };
  },
  computed: {
    errorMsg() {
      return this.msg;
    },
  },
  methods: {
    codeSend() {
      const inputs = {
        auth_c_code: this.code,
      };
      this.login(inputs);
    },
  },
};
</script>

<style scoped>
  .warning{
    width: 400px;
    margin: 0 auto;
  }
  .auth-c{
    text-align: center;
    margin: auto;
  }
  .messageWrap{
    text-align: center;
    margin:auto;
  }
  .message{
    font-size: 18px;
    margin-bottom: 40px;
  }
  .subMessage{
    font-size: 13px;
    font-weight: normal;
  }
  .inputWrap{
    width: 430px;
    margin:0 auto;
    margin-top:40px;
    text-align: left;
  }
  .login{
    width: 400px;
  }
  .login{
    background-color: #122889!important;
    color: #FFFFFF!important;
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
  }
  .remark{
    margin-top: 15px;
  }
</style>
